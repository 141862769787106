import { Box, Grid, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import React, { useState } from "react";
import { VideoPlayer } from "./VideoPlayer";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

type VideoModalProps = {
  isOpen: boolean;
  onClose: () => void;
  urls: string;
}

export const VideoModal: React.FC<VideoModalProps> = ({ isOpen, onClose, urls }) => {
  const urlList = urls.split(',');
  const [index, setIndex] = useState(0);
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="transparent">
        <ModalHeader>
          <ModalCloseButton color="white" />
        </ModalHeader>
        <ModalBody>
          <Grid alignItems="center" justifyItems="center">
            {urlList.length ? <VideoPlayer url={urlList[index]} /> : <Box />}
            <Grid bg="black" autoFlow="column" w="100%" placeContent="space-between">
              {index !== 0
                ? <ChevronLeftIcon onClick={() => setIndex(index - 1)} fontSize="2em" color="white" />
                : <ChevronLeftIcon fontSize="2em" color="grey.200" />}
              {!!urlList[index + 1]
                ? <ChevronRightIcon onClick={() => setIndex(index + 1)} fontSize="2em" color="white" />
                : <ChevronRightIcon fontSize="2em" color="grey.200" />}
            </Grid>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}